import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import { AppContext } from "Context/AppContext";
import { setAllStore } from "Helpers/setAllStore";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getDashboardData } from "../../../api/report";
import averageCommission from "../../../Assets/images/icons/home.png";
import iconImg from "../../../Assets/images/icons/how_to_vote-24px 1.png";
import orderImg from "../../../Assets/images/icons/local_offer-24px 1.png";
import newTickets from "../../../Assets/images/icons/Rectangle 194.png";
import orderTaken from "../../../Assets/images/icons/Vector (1).png";
import userReg from "../../../Assets/images/icons/Vector (2).png";
import commissionEarned from "../../../Assets/images/icons/Vector (3).png";
import totalRevenue from "../../../Assets/images/icons/Vector.png";
import Commission from "../charts/Commission";
import CustomerGrowth from "../charts/CustomerGrowth";
import PieChart from "../charts/PieChart";
import Productivity from "../charts/Productivity";
import RevenueBreakdown from "../charts/RevenueBreakdown";
//Charts
// import SalesGrowth from "../charts/SalesGrowth";
import Header from "./../../../Components/Header/Header";
import Listbox from "./Listbox";
import PopularProduct from "./PopularProduct";
import SearchItem from "./SearchItem";
import SortButton from "./SortButton";
import { DATE_TYPE } from "Helpers/Constants";

const Dashboard = (props) => {
  const [data, setData] = React.useState([]);
  let OrdersDetail = [];
  let SalesDetail = [];
  let commissionDetail = [];
  let CommissionData = "";
  let RevenueData = "";
  let CustomerGrowthData = "";
  // let SalesGrowthData = "";
  let ProductivityData = "";
  let revenueBreakDownData = "";

  let startDate = moment().format("YYYY-MM-DD");
  let endDate = moment().format("YYYY-MM-DD");
  const [type, setType] = useState("delivery_date");

  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);
  // const [loading, setLoading] = React.useState(false);
  const [timeValue, setTimeValue] = useState("Today");
  const [startDateDisplay, setStartDateDisplay] = useState(startDate);
  const [endDateDisplay, setEndDateDisplay] = useState(endDate);

  const [checkAllStore,setCheckAllStore] = useState(true);

  const setTimeValueFunction = async (data) => {
    setTimeValue(data);
    if (data === "Yesterday") {
      startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      endDate = moment().subtract(1, "days").format("YYYY-MM-DD");
    } else if (data === "Weekly") {
      startDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
      endDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
    } else if (data === "Monthly") {
      startDate = moment().startOf("month").format("YYYY-MM-DD");
      endDate = moment().endOf("month").format("YYYY-MM-DD");
    } else if (data === "Custom") {
      startDate = moment().format("YYYY-MM-DD");
      endDate = moment().add(31, "days").format("YYYY-MM-DD");
    } else {
      startDate = moment().format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }
    setStartDateDisplay(startDate);
    setEndDateDisplay(endDate);
    // fetchData(data);
  };

  const changeDuration = (selectedDate, dateType) => {
    let startDate = "";
    let endDate = "";

    if (dateType == DATE_TYPE.TO_DATE) {
      if (timeValue === "Weekly") {
        startDate = moment(selectedDate)
          .subtract(7, "days")
          .utc()
          .format("YYYY-MM-DD");

        endDate = moment(selectedDate).format("YYYY-MM-DD");
      } else if (timeValue === "Custom") {
        startDate = moment(selectedDate)
          .subtract(31, "days")
          .utc()
          .format("YYYY-MM-DD");

        endDate = moment(selectedDate).format("YYYY-MM-DD");
      } else {
        startDate = moment(selectedDate)
          .subtract(1, "month")
          .utc()
          .format("YYYY-MM-DD");

        endDate = moment(selectedDate).format("YYYY-MM-DD");
      }
      setStartDateDisplay(startDate);
      setEndDateDisplay(endDate)
    } else {
      if (timeValue === "Weekly") {
        endDate = moment(selectedDate).add(7, "days").utc().format("YYYY-MM-DD");

        startDate = moment(selectedDate).format("YYYY-MM-DD")
      } else if (timeValue === "Custom") {
        endDate = moment(selectedDate).add(31, "days").utc().format("YYYY-MM-DD");

        startDate = moment(selectedDate).format("YYYY-MM-DD")
      } else {
        endDate = moment(selectedDate).add(1, "month").utc().format("YYYY-MM-DD");

        startDate = moment(selectedDate).format("YYYY-MM-DD")
      }
      setEndDateDisplay(endDate);
      setStartDateDisplay(startDate)
    }
  };

  const fetchData = async (data) => {
    // console.log("timeValue=>",timeValue);
    // console.log("startDateDisplay=>",startDateDisplay);
    // console.log("endDateDisplay=>",endDateDisplay);
    // console.log("type=>",type);
    setIsLoading(true);

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      # 
    */
    setAllStore(checkAllStore);

    let range = "monthly";
    if (timeValue === "PastWeek") {
      range = "weekly";
    }
    const postData = {
      start_date: startDateDisplay,
      end_date: endDateDisplay,
      date_range_type: range,
      date_type: type,
    };

    const response = await getDashboardData(postData);
    if (response && response.status === 200) {
      setData(response.data.data);
    } else if (response?.status === 403) {
      // showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response?.data?.message, "error", false);
    }
    // setLoading(false);
    setIsLoading(false);
    
    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  useEffect(() => {
    fetchData("Today");
  }, []);

  if (data) {
    OrdersDetail = {
      link: "/admin/orders/process",
      data: [
        {
          number: data.ordersTaken ? data.ordersTaken : 0,
          icon: orderTaken,
          title: "Orders Taken",
        },
        {
          number: data.outForDelivery ? data.outForDelivery : 0,
          icon: iconImg,
          title: "Orders For Delivery",
        },
        {
          number: data.avgOrderValue ? `$` + data.avgOrderValue : 0,
          icon: orderImg,
          title: "Average Order Value",
        },
      ],
    };

    SalesDetail = {
      link: "/reports/sales-growth",
      data: [
        {
          number: data.totalRevenue ? `$` + data.totalRevenue : 0,
          icon: totalRevenue,
          title: "Total Revenue",
        },
        // {
        //   number: 0,
        //   icon: newTickets,
        //   title: "New Tickets",
        // },
        {
          number: data.newUserRegistration ? data.newUserRegistration : 0,
          icon: userReg,
          title: "User Registration",
        },
      ],
    };

    commissionDetail = {
      link: "/reports/commission",
      data: [
        {
          number: data.totalCommission ? `$` + data.totalCommission : `$` + 0,
          icon: commissionEarned,
          title: "Commission Earned",
        },
        {
          number: data.avgCommissionByUser
            ? `$` + data.avgCommissionByUser
            : `$` + 0,
          icon: totalRevenue,
          title: "Average Commission per Agent",
        },
        {
          number: data.avgCommissionByItems
            ? `$` + data.avgCommissionByItems
            : `$` + 0,
          icon: averageCommission,
          title: "Commission by Item",
        },
      ],
    };

    CommissionData = {
      label: data.commissionData ? data.commissionData.commissionDataKey : 0,
      data: data.commissionData ? data.commissionData.commissionDataValue : 0,
    };

    // SalesGrowthData = {
    //   label: data.salesGrowthData ? data.salesGrowthData.salesGrowthDataKey : 0,
    //   data: data.salesGrowthData
    //     ? data.salesGrowthData.salesGrowthDataValue
    //     : 0,
    // };

    ProductivityData = {
      label: data.productivityReportData
        ? data.productivityReportData.productivityReportDataKey
        : 0,
      data: data.productivityReportData
        ? data.productivityReportData.productivityReportDataValue
        : 0,
    };

    CustomerGrowthData = {
      label: data.customerGrowthReportData
        ? data.customerGrowthReportData.customerGrowthReportDataKey
        : 0,
      oldData: data.customerGrowthReportData
        ? data.customerGrowthReportData.customerGrowthReportDataValueOld
        : 0,
      newData: data.customerGrowthReportData
        ? data.customerGrowthReportData.customerGrowthReportDataValueNew
        : 0,
    };

    let revenueBreakDownPaymentKey = data.revenueBreakDownPayment?.map(
      (d, i) => d.title
    );
    let revenueBreakDownDataValue = data.revenueBreakDownPayment?.map(
      (d, i) => d.status
    );
    let finalKeyData = [];
    let finaDataValues = [];

    if (data.revenueBreakDownData) {
      finalKeyData = [
        ...data.revenueBreakDownData.revenueBreakDownDataKey,
        ...revenueBreakDownPaymentKey,
      ];
      finaDataValues = [
        ...data.revenueBreakDownData.revenueBreakDownDataValue,
        ...revenueBreakDownDataValue,
      ];
    }
    RevenueData = {
      label: finalKeyData ? finalKeyData : [],
      data: finaDataValues ? finaDataValues : [],
    };

    revenueBreakDownData = {
      revenueBreakDownPayment: data.revenueBreakDownPayment
        ? data.revenueBreakDownPayment
        : [],
      revenueBreakDownOrderData: data.revenueBreakDownOrderData
        ? data.revenueBreakDownOrderData
        : [],
      revenueBreakDownOrderCount: data.revenueBreakDownOrderCount
        ? data.revenueBreakDownOrderCount
        : [],
    };

    // mostPopularProduct = data.mostPopularProductData;
    // console.log(data.mostPopularProductData);
    // console.log(mostPopularProduct);
    // mostPopularProduct.forEach(element => {
    //     console.log(element);
    //     // mostPopularProduct.product.product_images = element.product?.product_images.sort((a, b) => b.dataValues.is_default_selected - a.dataValues.is_default_selected);
    // });
  }

  return (
    <React.Fragment>
      <Header title="Dashboard" />
      <div id="MainContent" className="main-content">
        <div className={`row`}>
          <div className="col-12 mb-2 d-flex align-items-center">
            <span
              className="mb-0 mt-2 pb-0 d-inline-bock mr-3"
              style={{ lineHeight: 1 }}
            >
              Filter By
            </span>
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("order_date");
                }
              }}
              label="Order date"
              value="order_date"
            />
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("delivery_date");
                }
              }}
              label="Delivery date"
              value="delivery_date"
            />
          </div>
          <div className={`col-md-2`}>
            <SortButton
              setTimeValue={setTimeValueFunction}
              timeValue={timeValue}
            />
          </div>
          <div className={`col-md-4`}>
            <SearchItem changeDuration={changeDuration} timeValue={timeValue} startDate={startDateDisplay} endDate={endDateDisplay} />
          </div>
          <div  style={{marginBottom: "5px"}}>
            <CheckBox
                value={checkAllStore}
                label="All Stores"
                onChange={(data)=>{setCheckAllStore(data)}}
            />
            </div>
          <div className="col-2">
            <button
              className="btn gray-btn d-inline-block mb-1 mr-2 mt-3"
              onClick={() => fetchData()}
            >
              Search
            </button>
          </div>
          <div style={{ paddingLeft: "0px" }} className={`col-md-3`}>
            <a href={OrdersDetail.link}>
              <Listbox list={OrdersDetail.data} />
            </a>
          </div>
          <div className={`col-md-9`}>
            <PopularProduct list={data.mostPopularProductData} />
          </div>
        </div>
        <br></br>
        <div className={`row`}>
          <div className={`col-md-9`}>
            {/* <SalesGrowth salesGrowthData={SalesGrowthData} /> */}
            <CustomerGrowth customerGrowthData={CustomerGrowthData} />
          </div>
          <div className={`col-md-3`}>
            <br></br>
            {/* <a href={SalesDetail.link}>
              <Listbox list={SalesDetail.data} />
            </a> */}
            <span>
              <Listbox list={SalesDetail.data} />
            </span>
          </div>
        </div>
        <br></br>
        <div className={`row`}>
          <div className={`col-md-11`}>
            <Productivity productivityData={ProductivityData} />
          </div>
          {/* <div className={`col-md-6`}>
            <CustomerGrowth customerGrowthData={CustomerGrowthData} />
          </div> */}
        </div>
        <br></br>
        <div className={`row`}>
          <div className={`col-md-9`}>
            <Commission commissionData={CommissionData} />
          </div>
          <div className={`col-md-3`}>
            <br></br>
            <a href={commissionDetail.link}>
              <Listbox list={commissionDetail.data} />
            </a>
          </div>
        </div>
        <br></br>
        <div className={` row `}>
          <div className={`col-md-9`}>
            <RevenueBreakdown revenueData={RevenueData} />
          </div>
          <div style={{ marginTop: "20px" }} className={`col-md-3 `}>
            <PieChart chartData={revenueBreakDownData} />
          </div>
        </div>
        <br></br>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Dashboard);
